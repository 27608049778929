import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCWyWOQ1LOn0Vm82vc7IgeY0ficNzI-HIo",
    authDomain: "project-unofficial.firebaseapp.com",
    projectId: "project-unofficial",
    storageBucket: "project-unofficial.appspot.com",
    messagingSenderId: "797959654105",
    appId: "1:797959654105:web:d2eb75ba969fbd79720cc8"
  };


const app = initializeApp(firebaseConfig);

   
  const auth = getAuth(app);
  
  
  const firestore = getFirestore(app);  
  export { auth, firestore };









// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

// export { auth }