import React, { useState } from 'react';
import {Link, useLocation } from 'react-router-dom';

export const Checkout= () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalAmount = queryParams.get('total') || 0;

  const [transactionId, setTransactionId] = useState('');

  // Function to handle transaction ID input change
  const handleTransactionIdChange = (event) => {
    setTransactionId(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can handle the submission logic
    console.log('Transaction ID:', transactionId);
    // Example: Send the transaction ID to your backend for verification
  };

  return (
    <div className="checkout-container d-flex flex-column align-items-center py-5">
    <h1 className="mb-4 text-center">Checkout</h1>
    <div className="amount mb-4 text-center"><h2>
      Total Amount: ${totalAmount}
      {/* Add a subtle green checkmark icon after the amount */}
      <i className="fas fa-check-circle ms-2 text-success"></i></h2>
    </div>
    <div className="qr-code mb-7 text-center">
      {/* Consider using a border or background for the QR code container */}
      <div className="qr-code-wrapper border rounded-circle d-flex justify-content-center align-items-center">
        <img className="img-fluid" src="https://i.ibb.co/KGYdMmY/QrCode1.jpg" alt="QR Code" width="300" height="550" />
      </div>
    </div>
    <br /><br /><br />
    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center">
      <div className="form-group mb-3">
        <label htmlFor="transactionId" className="form-label text-center"><h3>Transaction ID:</h3></label>
        <input
          type="text"
          className="form-control"
          id="transactionId"
          value={transactionId}
          onChange={handleTransactionIdChange}
          required
        />
      </div>
      <button type="submit" className="btn btn-warning btn-lg align-self-center"><Link to={`/thankyou`}>Verify Payment</Link></button>
    </form>
  </div>
  );
};