import { createContext, useEffect, useState } from "react";
import { PRODUCTS } from "../products";
import { PRODUCTSF } from "../productss";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
  let cart = {};
  for (let i = 1; i < 20 + 1; i++) {
    cart[i] = 0;
  }
  
  return cart;
};

export const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart());

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = PRODUCTS.find((product) => product.id === Number(item))||PRODUCTSF.find((product) => product.id === Number(item)) ;
         
        totalAmount += cartItems[item] * itemInfo.price;
      }
    }
    return totalAmount;
  };

  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
  };

  const updateCartItemCount = (newAmount, itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  };

  const checkout = () => {
    setCartItems(getDefaultCart());
  };

  const contextValue = {
    cartItems,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    checkout,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};


















// import { createContext, useEffect, useState } from "react";
// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/database";
// import { PRODUCTS } from "../products";
// import { PRODUCTSF } from "../productss";

// export const ShopContext = createContext(null);

// const getDefaultCart = () => {
//   let cart = {};
//   for (let i = 1; i < 20 + 1; i++) {
//     cart[i] = 0;
//   }
//   return cart;
// };

// export const ShopContextProvider = (props) => {
//   const [cartItems, setCartItems] = useState(getDefaultCart());

//   useEffect(() => {
//     // Fetch cart data from Firebase when component mounts
//     const user = firebase.auth().currentUser;
//     if (user) {
//       firebase
//         .database()
//         .ref(`carts/${user.uid}`)
//         .once("value")
//         .then((snapshot) => {
//           if (snapshot.exists()) {
//             setCartItems(snapshot.val());
//           }
//         });
//     }
//   }, []);

//   const updateCartData = (cart) => {
//     const user = firebase.auth().currentUser;
//     if (user) {
//       firebase.database().ref(`carts/${user.uid}`).set(cart);
//     }
//   };

//   const addToCart = (itemId) => {
//     setCartItems((prev) => {
//       const updatedCart = { ...prev, [itemId]: prev[itemId] + 1 };
//       updateCartData(updatedCart);
//       return updatedCart;
//     });
//   };

//   const removeFromCart = (itemId) => {
//     setCartItems((prev) => {
//       const updatedCart = { ...prev, [itemId]: Math.max(0, prev[itemId] - 1) };
//       updateCartData(updatedCart);
//       return updatedCart;
//     });
//   };

//   const updateCartItemCount = (newAmount, itemId) => {
//     setCartItems((prev) => {
//       const updatedCart = { ...prev, [itemId]: newAmount };
//       updateCartData(updatedCart);
//       return updatedCart;
//     });
//   };

//   const checkout = () => {
//     setCartItems(getDefaultCart());
//     updateCartData(getDefaultCart());
//   };

//   const contextValue = {
//     cartItems,
//     addToCart,
//     updateCartItemCount,
//     removeFromCart,
//     getTotalCartAmount,
//     checkout,
//   };

//   return (
//     <ShopContext.Provider value={contextValue}>
//       {props.children}
//     </ShopContext.Provider>
//   );
// };
