import { useContext, useEffect } from "react";
import { ShopContext } from "../../context/shop-context";
import { firestore } from "../../firebase";

export const CartItem = (props) => {
  const { id, productName, price, productImage } = props.data;
  const {
    cartItems,
    setCartItems, // Function to set the entire cart items
    addToCart,
    removeFromCart,
    updateCartItemCount,
  } = useContext(ShopContext);

  useEffect(() => {
    // Sync the cart with Firestore database on every update
    updateFirestoreCart();
  }, [cartItems]);

  const updateFirestoreCart = async () => {
    try {
      await firestore.collection("users").doc("currentUser").update({
        cartItems: cartItems,
      });
    } catch (error) {
      console.error("Error updating Firestore cart:", error);
    }
  };

  return (
    <div className="cartItem">
      <img src={productImage} alt={productName} />
      <div className="description">
        <p>
          <b>{productName}</b>
        </p>
        <p> Price: ${price}</p>
        <div className="countHandler">
          <button className="minus-btn" onClick={() => removeFromCart(id)}>
            {" "}
            -{" "}
          </button>
          <input
            value={cartItems[id]}
            onChange={(e) => updateCartItemCount(Number(e.target.value), id)}
          />
          <button onClick={() => addToCart(id)}> + </button>
        </div>
      </div>
    </div>
  );
};
