import React from "react";
import { Link } from "react-router-dom";
import { ShoppingCart } from "phosphor-react";
import "./navbar.css";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import image from "./11.png"

export const Navbar = () => {
  const navigate = useNavigate();
  const user = auth.currentUser;
  const logoutUser = async (e) => {
    e.preventDefault();

    await signOut(auth);
    navigate("/");
}
  return (
    <div className="navbar">
      <script src="https://sandbox.web.squarecdn.com/v1/square.js"></script>
     
      <div className="links">
        <img className="image" src={image} alt=" " />
      <ul>  
      <li> <Link to="/home"> Home </Link></li>   
      <li> <Link to="/men"> Men's </Link></li> 
      <li> <Link to="/women"> Women's </Link></li> 
      <li> <Link to="/contact"> Contact </Link></li> 
      
      <li> <Link to="/cart">
          <ShoppingCart size={32} />
        </Link></li> 
        <li  onClick = {(e) => logoutUser(e)}> <Link to="/"><button className="btn btn-danger  ">Logout</button>  </Link></li> 
        <li></li>
        </ul>
      </div>
    </div>
  );
};
