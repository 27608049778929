import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { auth } from "../firebase";
export const Thankyou = () => {
    const user = auth.currentUser||"user";
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card p-4 shadow">
            <h2 className="text-center mb-4"><FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Thank You </h2><h1 className="text-center mb-4">{ user.email }</h1> 
            <h2 className="text-center mb-4">for Shopping with Us!</h2>
        <br /> <h4> <p className="text-center mb-4">Your transaction is being verified. We will send you a confirmation email shortly.</p>
            <p className="text-center">If you have any questions or concerns, please feel free to contact us.</p>
            </h4>  </div>
        </div>   
      </div>
    </div>
  );
};  


