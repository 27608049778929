import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/navbar";
import Footer from "./components/footer";
import { Shop1, Shop2 } from "./pages/shop/shop";
import { Home } from "./pages/home/home";
import { Contact } from "./pages/contact";
import {Login} from "./pages/login";
import {Signup} from "./pages/signup";
import {Thankyou} from "./pages/thankyou";
import {Checkout} from "./pages/checkout";
import {Profile} from "./pages/profile";
import {Cart} from "./pages/cart/cart";

import { signOut } from "firebase/auth";
import { ShopContextProvider } from "./context/shop-context";
import { auth } from "./firebase"; // Import Firebase authentication object

function App() {
  const [user, setUser] = useState(null);
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (  
    <div className="App">
      <ShopContextProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/home" element={<Home />} />
            <Route path="/men" element={<Shop1 />} />
            <Route path="/women" element={<Shop2 />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/thankyou" element={<Thankyou />} />
          </Routes>
          <Footer/>
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
